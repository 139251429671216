import {React, useEffect, useRef, useState,} from 'react';
import './Center.css'
import Treemap from '../Treemap/Treemap';
import Date from '../Date/Date';
import CenterTypes from '../CenterTypes/CenterTypes';
import {s,p,f} from '../../utils/constants/formatSpecifiers'
import {URL, APIKEY} from '../../utils/constants/constants'
import {Helmet} from 'react-helmet-async'    
import image from '../../images/logo.svg'
    

function Center() {
    const [year, setYear] = useState(2021)
    const [centerData, setCenterData] = useState(null)
    const [value, setValue] = useState();
    const [toggleButton, setToggleButton] = useState(false);
    const buttonRef = useRef(null);
    const handleCloseButton = (e) => {
        setToggleButton(false) 
    }


        
    const handleOpenButton = (e) => {
        e.preventDefault(); 
        setToggleButton(true)
    }

    const handleNameChange = (value) => {
        setValue(value)
    }
    
    const handleYearChange = (e) => {
        setYear(e.target.id);
    }
    console.log(year)
    // const [isLoading, setisLoading] = useState(0)
    
    useEffect(() => {
        const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json", 'x-api-key': APIKEY},
            body: JSON.stringify({ year: year }),
        };
            fetch(`${URL}/center`, requestOptions)
            .then((res) => res.json())
            .then((res) => res)
            .then((res) => {setCenterData(res)})
            // .then((res) => {setisLoading(1)})
            .catch((err) => console.log(err))
        },[year])
    //Меняем формат цифр 

    const navigation = (d) => {
        // const map1 = data.map((x) => x.data.name)
        return d.ancestors().reverse().slice(1).map((d) => {
            return <li className='center__map'>
                <span className='center__span'>&#8595;</span>
                <p className='center__text'>{d.data.name}<br/>({f(p(d.value))} млн руб.)</p>
                <div className='center__arrow'></div>
                </li>
        });
    }

    return (
    <>
        <Helmet>
            <title data-react-helmet="true">Доходы и расходы федерального бюджета России </title>
            <meta name="description" content="Cколько тратит федеральный бюджет? Визуализация, Бюджетные данные простым."/>
            <meta property="og:title" content="Федеральный бюджет России"/>
            <meta property="og:description" content="Визуализация доходов и расходов федерального бюджета России"/>
            <meta property="og:image" content={image} />
            <meta property="og:url" content={`https://budgetdata.ru/center`}/>
            <meta property="og:type" content="website"/>
            <link rel="canonical" href="/center"></link>
        </Helmet>
<section className="center__content">
    <div className="center">
    {/* <div className="center__title"><h1 className="">Федеральный бюджет</h1></div> */}
    <div className='center__dropdown' onMouseMove={(e) => handleOpenButton(e)} onMouseLeave={(e) => handleCloseButton(e)}>
                <button  id={"0"} className ="center__dropdown-article-button" ref={buttonRef} onClick={(e) => handleOpenButton(e)}>Год&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                {toggleButton ? (
                <ul className="center__dropdown-list center__dropdown-list_years" ref={buttonRef}  onClick={() => handleCloseButton()}>
                <li className="center__dropdown-item">
                <button id={2023} className='center__dropdown-button center__dropdown-text' onClick={(e) => {handleYearChange(e)}}>2023</button>
                </li>
                <li className="center__dropdown-item">
                <button id={2022} className='center__dropdown-button center__dropdown-text' onClick={(e) => {handleYearChange(e)}}>2022</button>
                </li>
                <li className="center__dropdown-item">
                <button id={2021} className='center__dropdown-button center__dropdown-text' onClick={(e) => {handleYearChange(e)}}>2021</button>
                </li>
                <li className="center__dropdown-item">
                <button id={2020} className='center__dropdown-button center__dropdown-text' onClick={(e) => {handleYearChange(e)}}>2020</button>
                </li>
                </ul>
    ): null}
    </div>
    <div className="center__panel">
    {/* <CenterTypes /> */}
    {/* <Date onClick={handleYearChange}/> */}
    <p className="center__explanation">{`[Клик x2 - перейти на следующий уровень,\nКлик x1 - посмотреть доп. информацию]`}</p>   
    <ul className='center__test'>
        <p className='center__value'>Российская Федерация, {year}, млн руб 
        </p>
        {value}
        </ul>
    </div>
    <div className="center__treemap">
    {/* <p className="center__value">.</p> */}
    {centerData==null ? (
    <div className='center__loading'>Загрузка...</div>) : ( 
    <Treemap navigation={navigation} onChange={handleNameChange} data={centerData} />
    )}
    </div>
    </div>
</section>
    </>
    )
}

export default Center;