import { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import {REGIONFROMMAP, REGIONFROMLIST } from './boilerplate'
const initialState = JSON.parse(localStorage.getItem('appState')) || { counter: 0 };

function reducer (state, action) {
    switch(action.type) {
      case REGIONFROMMAP:
      return {
        counter: action.payload,
      }
      case REGIONFROMLIST :
      return {
        counter: action.payload, 
      }
  } 
}

export default function usePersistedReducer() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('appState', JSON.stringify(state));
  }, [state]);

  // Wrapping navigate to use within dispatch
  const enhancedDispatch = (action) => {
    if (action.type === 'NAVIGATE') {
      navigate(action.payload);
    } else {
      dispatch(action);
    }
  };

  return [state, enhancedDispatch];
}