import {React, useState, useEffect, useRef} from 'react';
import {useParams, useNavigate} from 'react-router-dom'
import {Helmet} from 'react-helmet-async';
import './Regions.css'
import Treemap from '../Treemap/Treemap';
import Date from '../Date/Date';
import RegionsList from '../RegionsList/RegionsList'
import {URL, APIKEY} from '../../utils/constants/constants'
import {s,p,f} from '../../utils/constants/formatSpecifiers'
import data from '../../utils/constants/region_codes.json'
import {regionList} from '../../utils/constants/actions'
import image from '../../images/logo.svg'



function Regions({state, dispatch}) {
    const [year, setYear] = useState(2021)
    const [regionsData, setRegionsData] = useState(null)
    const [regionsName, setRegionsName] = useState(null)
    const navRef = useRef(null);
    const navigate = useNavigate();
    const {name} = useParams();
    const [value, setValue] = useState();
    const buttonRef = useRef(null);
    const [area, setArea] = useState('');
    const [closeForm, setCloseForm] = useState('');

    
// const region = data.find(item => item.id === state.counter) === name
    
    const handleNameChange = (value) => {
        setValue(value)
    }

    function findUrlNameByCounter() {
        const region = data.find(item => item.id === state.counter);
        if (region) {
          return region.name_eng.replace(/\s+/g, '-').toLowerCase();
        }
        return 'unknown';
      }
    // Function to update the region URL
    const name_eng = findUrlNameByCounter();

    // console.log(name)
    // console.log(data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name).id === state.counter)
 
    // if (data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name).id) !== state.counter {
    //   return findUrlNameByCounter
    // }

    // console.log(typeof data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name))
    // Recompute and update the route whenever the counter changes
    useEffect(() => {
      if(data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name)){
      if (data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name).id === state.counter){
      navigate(`/regions/${name_eng}`)
        }else{
      dispatch(regionList((data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name).id)))
      }
    }else{
      navigate('/not-a-region', { replace: true })
    };
    }, [state, name]);
      //     if (name) {
      //       state.counter = data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name).id;
      //       // if (counter !== null && counter !== state.counter) {
      //           dispatch({ type: 'REGIONFROMLIST', payload: state.counter });
      //       }
      //   }, [name, state, dispatch]);

    function findRuNameByCounter() {
        const region = data.find(item => item.id === state.counter);
        if (region) {
          return region.name_rp
        }
        return 'unknown';
      }

      const name_rp = findRuNameByCounter();
      
      // useEffect(() => {
      //   // let regionId = data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name).id;
      //     if (name) {
      //       state.counter = data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name).id;
      //       // if (counter !== null && counter !== state.counter) {
      //           dispatch({ type: 'REGIONFROMLIST', payload: state.counter });
      //       }
      //   }, [name, state, dispatch]);
    //   //Убираем dropdown list при клике
    //   useEffect(() => {
    //     function handleClickOutside(event) {
    //       if (buttonRef.current && !buttonRef.current.contains(event.target)) {
    //         setOpenButton(0);  // Change the state if clicked outside the button
    //         // setToggleButton(!toggleButton)
    //       }
    //     }
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //       document.removeEventListener('mousedown', handleClickOutside);
    //     };
    //   }, []);
    
    // const updateRegionsData = (newValue) => {
    //     if (regionsData !== newValue) {
    //     setRegionsData(newValue);
    //     }
    //   };

    useEffect(() => {
        // POST request using fetch inside useEffect React hook
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': APIKEY},
            body: JSON.stringify({ counter: state.counter-1, year: year }),
        };
        fetch(`${URL}/regions`, requestOptions)
            .then(res => res.json())
            .then(res => res)
            .then(res => {setRegionsData(res.data); setRegionsName(res.name)});
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [state, year]);
    
    // const getCenterData = async () => {
    //     try {
    //     const response = await fetch("http://localhost:3000/center");
    //     const data = await response.json();
    //     setCenterData(data);
    //     } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // }

    // console.log(dataFetch())
    // const handleData = () =>{
    //     getCenterData()
    //     .then((data) =>{
    //         setCenterData(data);
    //     })
    //     .catch((err) => console.log(err));
    // }

    // console.log(centerData)

    // const acc = (d) => {
    //     // let initialValue = 0
    //     d.reduce((a, b)  => a + b, 1)
    //     //     (accumulator, currentValue) => accumulator + currentValue,
    //     //     initialValue,
    //     // return (d.value/d.parent.value*100).toFixed(0)
    // }

    
    //Меняем формат цифр 
    

    const navigation = (d) => {
        // const map1 = data.map((x) => x.data.name)
        return d.ancestors().reverse().slice(1).map((d) => {
         return <li key={d.data.name} className='regions__map'>
        <span className='regions__span'>&#8595;</span>
         <p className='regions__text'>{d.data.name}<br/>({f(p(d.value))} млн руб.)</p>
         <div className='regions__arrow'></div>
         </li>
        });
    }

    const onChange = (e) =>{
        setArea(e.target.value)
    }
    //Убираем dropdown list при клике
        function handleClickOutside(event) {
          if (buttonRef.current && !buttonRef.current.contains(event.target)) {
            setArea('');  // Change the state if clicked outside the button
          }
        }

        function handleKeydown(e) {
          //define event listener
          if (e.key === 'Escape')
             setArea('');
            // setToggleButton(!toggleButton)
        }

        // Add event listeners when the component mounts
  useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeydown);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('keydown', handleKeydown);
    };
  }, []);

    // Переключатель регионов в RegionList'e
    const handleRegionChange = (item) => {
    if (typeof item === "number") {
        dispatch(regionList(item))
        setArea("")
    }
    const regionListName = data.find(d => d.id === item).name_eng.replace(/\s+/g, '-').toLowerCase();
    navigate(`/regions/${regionListName}`)
    }

    const onSearch = (searchTerm) => {
        //Добавляет слово из выпадающего списка в поле инпута поиска
        // here we can get our api to fetch the search result   
        setArea(searchTerm)
        // console.log('search', searchTerm)
    }
    // const name = gdata[state.counter-1].name
    return (
        <>
    {data.find(item => item.name_eng.replace(/\s+/g, '-').toLowerCase() === name) &&
    <Helmet>
        <title>{`Бюджет ${name_rp}`}</title>
        <meta name="description" content={`Расходы и доходы ${name_rp}. Простая и понятная визуализация бюджета региона.`}></meta>
        <meta property="og:title" content={`Бюджет ${name_rp}`}/>
        <meta property="og:description" content={`Визуализация доходов и расходов ${name_rp}`}/>
        <meta property="og:image" content={image} />
        <meta property="og:url" content={`https://budgetdata.ru/${name_eng}`}/>
        <meta property="og:type" content="website"/>
        <link rel="canonical" href={`/regions/${name_eng}`}></link>
    </Helmet>
    }
    <section className="regions__content">
    <RegionsList className="regions__search" setYear={setYear} dispatch={dispatch} state={state} area={area} onChange={onChange} onSearch={onSearch} handleRegionChange={handleRegionChange} ref={buttonRef}/>
    <p className="regions__explanation">{`[Клик x2 - перейти на следующий уровень,\nКлик x1 - посмотреть доп. информацию]`}</p>
    <div className="regions">
    {/* <div className="regions__title"><h1>Бюджеты субъектов Российской Федерации </h1></div> */}
    <div className="regions__list">
    {/* <Date onClick={handleYearChange}/> */}
    <ul className='regions__test'>
    <p className='regions__value'>{regionsName}, {year}, млн руб.
    </p>{value}</ul>
    </div>
    {/* <div className="regions__space"></div> */}
    <div className="regions__treemap">
    {/* <p className='regions__value'>{name} {year}, млн руб.</p> */}
    {regionsData  === null ? (
        <div className='regions__loading'>Загрузка...</div>) : (  
    <Treemap year={year} state={state} ref={navRef} navigation={navigation} onChange={handleNameChange} data={regionsData}/>
    )}
    </div>
    </div>
</section>
</>
    )
}

export default Regions;