import * as d3 from 'd3'

//Меняем формат цифр
export const s = d3.formatSpecifier("f");
s.precision = d3.precisionFixed(0);
export  const p = d3.format(s)
const locale = d3.formatLocale({
    decimal: ".",
    thousands: "\u00a0",
    grouping: [3]
  })
  export const f = locale.format(",")
