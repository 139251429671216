import {React, useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import Treemap from '../Treemap/Treemap';
import {s,p,f} from '../../utils/constants/formatSpecifiers'
import {FUNDS, URL, APIKEY} from '../../utils/constants/constants';
import {Helmet} from 'react-helmet-async'; 
import image from '../../images/logo.svg'; 
import "./Extra.css";

export default function Extra () {
  const [openButton, setOpenButton] = useState({id: 0, value: false});
  const [extraData, setExtraData] = useState(null)
  const [extraName, setExtraName] = useState(null)
  const [toggleButton, setToggleButton] = useState(false);
  const [index, setIndex] = useState(0)
  const [year, setYear] = useState(2020)
  const [value, setValue] = useState("");
  const buttonRef = useRef(null);
 
  const handleNameChange = (value) => {
    setValue(value)
}


  useEffect(() => {
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': APIKEY},
        body: JSON.stringify({ counter: index, year: year }),
    };
    setExtraData(null)
    fetch(`${URL}/extra`, requestOptions)
        .then(res => res.json())
        .then(res => res)
        .then(res => {setExtraData(res.data); setExtraName(res.name)});
// empty dependency array means this effect will only run once (like componentDidMount in classes)
}, [index, year]);

console.log(extraName)
// console.log(index)

  const handleOpenButton = (e) => {
    e.preventDefault(); 
    setToggleButton(true)
    setOpenButton(e.target.id);
  }

  const handleCloseButton = (e) => {
    setToggleButton(false) 
}
// console.log(value)
useEffect(() => {
  function handleClickOutside(event) {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setOpenButton(0);  // Change the state if clicked outside the button
    }
  }
      document.addEventListener('mousedown', handleClickOutside);
  return () => {
      document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

useEffect(() => {
  function onKeyup(e) {
    //define event listener
    if (e.key === 'Escape')
      setOpenButton(0);
      // setToggleButton(!toggleButton)
  }
    //register our listener
  window.addEventListener('keyup', onKeyup);
    //unregister our listener
  return () => window.removeEventListener('keyup', onKeyup);
}, []);

const navigation = (d) => {
  // const map1 = data.map((x) => x.data.name)
  return d.ancestors().reverse().slice(1).map((d) => {
      return <li className='center__map'>
          <span className='center__span'>&#8595;</span><br/>
          <p className='center__text'>{d.data.name}<br/>({f(p(d.value))} млн руб.)</p>
          <div className='center__arrow'></div>
          </li>
  });
}

  return (
    <>
  <Helmet>
    <title>Доходы и расходы внебюджетных фондов России </title>
    <meta name="description" content="Cколько тратит и зарабатывает Пенсионный фонд России (ПФР)? Cколько тратит и зарабатывает Фонд обязательного медицинского страхования России (ФОМС)? Cколько тратит и зарабатывает Фонд социального страхования России (ФСС)?"></meta>
    <meta property="og:title" content="Внебюджетные фонды России"/>
    <meta property="og:description" content="Визуализация доходов и расходов внебюджетных фондов России"/>
    <meta property="og:image" content={image} />
    <meta property="og:url" content={`https://budgetdata.ru/extra`}/>
    <meta property="og:type" content="website"/>
    <link rel="canonical" href="/extra"></link>
  </Helmet>
    <section className="extra__content">
      <div className="extra__buttons">
          <div className='extra__dropdown'>
          <button  id={"1"} className ="extra__dropdown-article-button" ref={buttonRef} onClick={(e) => handleOpenButton(e)} onMouseMove={(e) => handleOpenButton(e)}>Фонд</button>
                {toggleButton && openButton === "1" ? (
                <ul className="extra__dropdown-list" ref={buttonRef}  onClick={() => handleCloseButton()} onMouseLeave={() => handleCloseButton()}>
                {FUNDS.map((item) => (
                  <li key={item.id} className="extra__dropdown-item">
                  <button className='extra__dropdown-button extra__dropdown-text' onClick={() => {setIndex(item.id)}}><pre className='extra__dropdown-text'>{item.name_ru}</pre></button>
                  </li>
                  ))}
                  </ul>) : null}
    </div>
    <div className='extra__dropdown'>
                <button  id={"2"} className ="extra__dropdown-article-button" ref={buttonRef} onClick={(e) => handleOpenButton(e)} onMouseMove={(e) => handleOpenButton(e)}>Год</button>
                {toggleButton && openButton === "2" ? (
                <ul className="extra__dropdown-list extra__dropdown-list_years" ref={buttonRef}  onClick={() => handleCloseButton()} onMouseLeave={() => handleCloseButton()}>
                  <li className="extra__dropdown-item">
                  <button className='extra__dropdown-button extra__dropdown-text' onClick={() => {setOpenButton(0)}}>2020</button>
                  </li>
                </ul>
                ): null}
      </div>  
    </div>
    <p className="extra__explanation">{`[Клик x2 - перейти на следующий уровень,\nКлик x1 - посмотреть доп. информацию]`}</p>
    <div className="extra">
    <div className="extra__list">
    <ul className='extra__test'>
    <p className='extra__value'>{extraName}, {year}, млн руб.
    </p>{value}</ul>
    </div>
      
    {/* <div className="extra__space"></div> */}
    <div className="extra__treemap">
    {extraData  === null ? (
        <div className='extra__loading'>Загрузка...</div>) : (  
    <Treemap navigation={navigation} onChange={handleNameChange} data={extraData} />
    )}
    </div>
    </div>
</section>
</>
        )
    }