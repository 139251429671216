import React, {useState, useRef, useEffect,forwardRef} from 'react'
import './RegionsList.css'
import data from '../../utils/constants/region_codes.json'

    //  https://www.youtube.com/watch?v=Jd7s7egjt30

const RegionsList = forwardRef(({state, dispatch, area, onSearch, onChange, handleRegionChange, setYear}, ref) => {
    // const [year, setYear] = useState(2020)
    // const [openButton, setOpenButton] = useState({id: 0, value: false});
    const [toggleButton, setToggleButton] = useState(false);
    const buttonRef = useRef(null);
    const handleCloseButton = (e) => {
        setToggleButton(false) 
    }

    const handleYearChange = (e) => {
        setYear(e.target.id);
    };
    

        
    const handleOpenButton = (e) => {
        e.preventDefault(); 
        setToggleButton(true)
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }   
    
    return (
    <>
    <div className="list">

    <div className='list__search-form'>

    <form className="list__search-container" onSubmit={onFormSubmit}>
        <input className='list__input' type="text" value={area} onChange={onChange} placeholder="Найти регион..."></input>
        {/* search button  */}
        {/* <button className='list__search-button' onClick={() => {onSearch(area); handleRegionChange(area)}} type="submit"><div className='list__search-button_triangle'></div></button> */}
        <div className='list__dropdown'>
        {/* check -> filter the results and function to pass my filter or not */}
        {data.filter(item => {

        // I want to compare strings. I want to make all of the LowerCase to make it easier to compare them
            const searchTerm = area.toLowerCase();
            const regionName = item.name.toLowerCase();
            
        // Now lets check  if actually this function returns true the item pass and will be shown here if follows the item will be filtered
            return searchTerm && regionName.includes(searchTerm) && regionName !== searchTerm
        })
        .map((item) => (
            <div className='list_dropdown-row' onClick={() => {onSearch(item.name); handleRegionChange(item.id)}} type="submit" key={item.id}> 
            {item.name}
            </div>))
            //max rows in dropdown
        .slice(0,5)}
    </div>
    </form>
        {/* list dropdown */}
    </div>
    <div className='list__dropdown-year' onMouseMove={(e) => handleOpenButton(e)} onMouseLeave={(e) => handleCloseButton(e)}>
                <button  id={"0"} className ="list__dropdown-article-button" ref={buttonRef} onClick={(e) => handleOpenButton(e)}>Год&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
                {toggleButton ? (
                <ul className="list__dropdown-list list__dropdown-list_years" ref={buttonRef}  onClick={() => handleCloseButton()}>
                <li className="list__dropdown-item">
                <button id={2021} className='list__dropdown-button list__dropdown-text' onClick={(e) => {handleYearChange(e)}}>2021</button>
                </li>
                <li className="list__dropdown-item">
                <button id={2020} className='list__dropdown-button list__dropdown-text' onClick={(e) => {handleYearChange(e)}}>2020</button>
                </li>
                </ul>
    ): null}
    </div>
    </div>
    </>
    )
})

export default RegionsList