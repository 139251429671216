import React from 'react';
import {Link} from "react-router-dom";
import './Footer.css'
import linkedin from '../../images/linkedin.png'

function Footer() {
    return (
    <footer className="footer">
    <div className="footer__container">
    <p className="footer__text">Артем Зубов, 2024</p>
    <Link to={"https://www.linkedin.com/in/artem-zubov-4b1451186/"} target="_blank">
            <img className="footer__logo" src={linkedin} alt="linkedin" />
    </Link>
    </div>
    </footer>
    )
}

export default Footer;