import {React} from "react";
import { Link } from "react-router-dom";
import {Helmet} from 'react-helmet-async';
import image from '../../images/logo.svg'; 
import './About.css';

export default function About () {
    return (
    <>
    <Helmet>
      <title>Как работает бюджетная система России?</title>
      <meta name="description" content="Как работает бюджетная система России?"></meta>
      <meta property="og:title" content="О Бюджетной системе России"/>
      <meta property="og:description" content="Как работает бюджетная система России"/>
      <meta property="og:image" content={image} />
      <meta property="og:url" content={`https://budgetdata.ru/methodology`}/>
      <meta property="og:type" content="website"/>
      <link rel="canonical" href="/methodology"></link>
    </Helmet>
      <section className="about">
        <div className="about__container">
        <h1 className="about__title">Источники</h1>
         <h2 className="about__text">Данные взяты из официального сайта Казначейства Российской Федерации</h2> 
         </div>
      </section>
    </>
    )
  }