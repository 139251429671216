import {React, useState, useEffect} from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";

function Navigation({active, handleActiveClick, isShowMenu, setIsShowMenu}) {
    
    const handleCloseBurgerMenu = () =>{
        return isShowMenu ? setIsShowMenu(false) : null
    }


  return (
    <>
    <Link id={"1"} className={`navigation__link navigation__link_main ${active === "1" ? "navigation__link_active" : null}`}  to="/center" onClick={(e) => { handleActiveClick(e); handleCloseBurgerMenu()}}>
        Федеральный бюджет
    </Link>
    <Link id={"2"} className={`navigation__link navigation__link_main ${active === "2" ? "navigation__link_active" : null}`} onClick={(e) => {handleActiveClick(e); handleCloseBurgerMenu()}} to="/regions/moscow">
        Региональные бюджеты
    </Link>
    <Link id={"3"} className={`navigation__link navigation__link_main ${active === "3" ? "navigation__link_active" : null}`} onClick={(e) => {handleActiveClick(e); handleCloseBurgerMenu()}} to="/extra">
        Внебюджетные фонды
    </Link>
    <Link id={"4"} className={`navigation__link navigation__link_main ${active === "4" ? "navigation__link_active" : null}`} onClick={(e) => {handleActiveClick(e); handleCloseBurgerMenu()}} to="/methodology">
        О бюджете
    </Link>
    </>
  );
}

export default Navigation;